import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Back from "../components/Back";

// eslint-disable-next-line
export const CareerListingTemplate = ({
  content,
  contentComponent,
  description,
  title,
  location,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section mb-6">
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Careers - Job Openings - Kamsa</title>
          <meta property="og:title" content="Careers - Job Openings - Kamsa" />
          <meta name="description" content="Grow your career with Kamsa. Explore current job openings." />
        </Helmet>
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="block has-text-centered mb-0 px-auto no-padding-mobile">
              <h1 className="title is-h1">{ title }</h1>
              <h4 className="is-h4">{ description }</h4>
            </div>
            <hr/>
            <div className="block mt-4 has-text-centered">
              <h4 className="is-h4">Location: { location }</h4>
            </div>

            <PostContent content={content} />

            <div className="block">
              <a href="mailto:team@kamsa.co">
                To apply for this position, send your cover letter and resume to team@kamsa.co
              </a>
            </div>

            <div className="block has-text-centered">
              <Back
                title="Careers"
                url="/careers"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

CareerListingTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string,
  helmet: PropTypes.object,
};

const CareerListing = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <CareerListingTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        location={post.frontmatter.location}
        helmet={
          <Helmet titleTemplate="%s | Articles">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

CareerListing.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CareerListing;

export const pageQuery = graphql`
  query CareerListingByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        location
      }
    }
  }
`;
